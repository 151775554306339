<template>
  <div class="list-actions space-x-2" v-if="hasActions()">
    <slot name="default"/>
  </div>
</template>

<script>
  export default {
    name: "InlineActions",
    props: {
      rowData: {
        type: Object,
      }
    },
    methods: {
      hasActions() {
        return !!this.$slots.default;
      }
    }
  }
</script>

<style scoped>
  .list-actions {
    @apply flex justify-end cursor-pointer text-blue-500;
    display: flex;
    visibility: hidden;
  }

  tbody tr:hover .list-actions {
    flex-wrap: wrap;
    visibility: visible;
  }
</style>
