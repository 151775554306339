<template>
  <div>
  <div class="flex" :class="[listFilterClasses.container]">
    <div v-if="hasFilters" class="form-col">
      viewing:
      <Tag v-if="filterIsEmpty" text="all"></Tag>
      <span v-for="(filterValue, filterName) in chips" :key="filterName" class="mb-1 inline-block">
        <span v-if="filterValue.length">
          <Tag
            v-for="item in filterValue"
            :text="item.value"
            :key="item.key"
            deletable
            @click="removeFilter(filterName, item.key)"
          />
        </span>
        <Tag
          v-else
          :text="filterValue.label"
          deletable
          @click="removeFilter(filterName)"
        />
      </span>
    </div>
    <div v-if="hasQuickFilters" :class="[listFilterClasses.quickFilters]">
      <slot name="quickfilter"></slot>
    </div>
    <div
      v-if="search || hasFilters"
      class="flex search-container"
      :class="[{'w-1/2': !hasFilters && !hasQuickFilters}, {'flex-1 ml-8': hasFilters}, listFilterClasses.searchContainer]"
    >
      <text-input
        v-if="search"
        :value="query"
        :placeholder="searchPlaceholder"
        @input="applySearch"
        @clear="clearSearch"
        clearable
      >
        <template v-slot:leading-icon>
          <Icon name="search2" class="w-4 h-4 text-graphite-800"/>
        </template>
      </text-input>
      <button ref="filter-btn" v-if="hasFilters" class="btn-filter" @click.stop="toggleFilters">
        <Icon name="filter" class="h-6 w-6"/>
      </button>
    </div>
    <div class="filter-dropdown" v-show="showFilters" ref="filter-dropdown">
      <FinalForm
        ref="filterForm"
        :submit="handleFilterChange"
        :initialValues="initialValues"
      >
        <template v-slot="props">
          <form @submit="props.handleSubmit">
            <slot name="filters" v-bind:reset="props.reset"></slot>
          </form>
        </template>
      </FinalForm>
    </div>
  </div>
  <slot name="loader"></slot>
  </div>
</template>

<script>
import {FinalForm} from 'vue-final-form';
import moment from "moment";
import Icon from "@/components/ui/Icon";
import Tag from "@/components/ui/Tag";
import TextInput from '@/components/ui/TextInput';


const defaultClasses = {
  container: '',
  quickFilters: '',
  searchContainer: '',
};

export default {
  name: 'ListFilter',
  components: {
    FinalForm,
    Icon,
    Tag,
    TextInput,
  },
  props: {
    filterClasses: {
      type: Object,
      default: () => ({}),
    },
    applyFilter: {
      type: Function,
      required: true
    },
    initialValues: {
      type: Object,
      required: false,
      default: () => ({})
    },
    applySearch: {
      type: Function
    },
    clearSearch: {
      type: Function
    },
    query: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Start typing to search'
    },
    hasFilters: {
      type: Boolean
    },
    hasQuickFilters: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      showFilters: false,
      chips: {}
    };
  },
  computed: {
    filterIsEmpty() {
      return !Object.keys(this.chips).length;
    },
    listFilterClasses() {
      return {
        ...defaultClasses,
        ...this.filterClasses,
      }
    }
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    removeFilter(filterName, filterValue = undefined) {
      const {finalForm: {change}, formState: {values}} = this.$refs.filterForm;

      const newVal = filterValue
        ? values[filterName].filter(item => item !== filterValue)
        : this.initialValues[filterName];

      change(filterName, newVal);
      this.handleFilterChange({...values, [filterName]: newVal});
    },
    generateChips(val) {
      const chips = {};

      Object.keys(val).forEach(key => {
        const filterValue = val[key];
        if (val[key]) {
          const filterField = this.$refs.filterForm.$children.find(filter => filter.$options.propsData.name === key);
          switch (filterField?.$options.name) {
            case 'SelectInput':
              if (Array.isArray(filterValue)) {
                if (filterValue.length > 0) {
                  chips[key] = filterValue.map((id) => ({
                    key: id,
                    value: filterField.options.find(opt => opt.key === id)?.value
                  }));
                }
              } else {
                chips[key] = {
                  value: filterValue,
                  label: filterField.options.find(opt => opt.key === filterValue)?.value
                };
              }
              break;
            case 'DateRangeInput':
              if (filterValue['startDate'] && filterValue['endDate']) {
                chips[key] = {
                  value: filterValue,
                  label: `${filterField.$options.propsData.label}: ${moment(filterValue['startDate']).format('LL')} - ${moment(filterValue['endDate']).format('LL')}`
                }
              }
              break;
            case 'TextField':
              if (filterValue) {
                chips[key] = {
                  value: filterValue,
                  label: `${key}: ${filterValue}`,
                };
              }
              break;
            default:
              return;
          }
        }
      });

      return chips;
    },
    handleFilterChange(val) {
      this.chips = this.generateChips(val);
      this.applyFilter(val);
      this.showFilters = false;
    },
    handleOutsideClickEvent(e) {
      if(this.showFilters) {
        const el = this.$refs['filter-dropdown'];

        if (el && !el.contains(e.target) && !e.target.closest('.daterangepicker')) {
          this.showFilters = false;
        }
      }
    },
  },
  created() {
    window.addEventListener('click', this.handleOutsideClickEvent);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleOutsideClickEvent);
  }
};
</script>

<style scoped>

.search-container {
  height: fit-content;
}


.btn-filter {
  @apply p-3 outline-none;
}

.btn-filter:hover {
  @apply text-blue-500;
}

.filter-dropdown {
  @apply absolute w-full bg-blue-50 p-4 border border-blue-100 z-20;
  box-shadow: 0 0.5rem 1rem rgba(222, 224, 228, 0.5);
  top: 4rem;
}

.filter-dropdown:after,
.filter-dropdown:before {
  content: '';
  display: block;
  position: absolute;
  right: 12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px;
}

.filter-dropdown:after {
  top: -22px;
  border-color: transparent transparent #F7FCFF transparent;
}

.filter-dropdown:before {
  top: -24px;
  border-color: transparent transparent #EAF6FF transparent;
}
</style>
