
   
<template>
  <div class="list-container">
    <ListFilter
      ref="filter"
      :class="{'mb-8': hasFilters || search}"
      :filter="filter"
      :hasFilters="hasFilters"
      :hasQuickFilters="hasQuickFilters"
      :search="search"
      :search-placeholder="searchPlaceholder"
      :filter-classes="filterClasses"
      :initial-values="initialFilter"
      :apply-filter="values => applyFilter(filterMapper(values))"
      :apply-search="applySearch"
    >
      <template v-slot:filters="props">
        <slot name="filters" :reset="props.reset" :filter="filter" />
      </template>
      <template v-slot:quickfilter>
        <slot name="quickfilter" :applyFilter="applyFilter"></slot>
      </template>
      <template v-slot:loader>
        <loader :loading="loading" :backdrop="true"/>
      </template>
    </ListFilter>
    <vuetable
      ref="vuetable"
      :rowClass="rowClass"
      :api-mode="false"
      :fields="fields"
      :data="{data}"
      :data-manager="dataManager"
      :css="css.table"
      :track-by="trackBy"
      :sort-order="innerSortOrder"
      :detail-row-component="detailRow"
      :detail-row-options="detailOptions"
      :no-data-template="noDataTemplate"
      pagination-path="pagination"
      @vuetable:row-clicked="handleRowClicked"
      @vuetable:cell-clicked="handleCellClicked"
      @vuetable:pagination-data="onPaginationData"
    >
      <template v-slot:empty-result>
        <slot name="empty-result"></slot>
      </template>
      <template v-slot:actions="props">
        <div v-if="hasActions">
          <ListActions :record="props.rowData">
            <slot name="actions" :record="props.rowData"/>
          </ListActions>
        </div>
      </template>
      <template v-slot:inline-actions="props">
        <div v-if="hasInlineActions">
          <InlineActions :record="props.rowData">
            <slot name="inline-actions" :record="props.rowData"/>
          </InlineActions>
        </div>
      </template>
      <template v-slot:detail-toggler="props">
        <div v-if="hasDetailRow">
          <DetailToggler :rowData="props.rowData" :rowIndex="props.rowIndex">
            <template v-slot="detailProps">
              <slot name="detail-toggler" :record="props.rowData" :isOpen="detailProps.isOpen" :toggleRow="detailProps.toggleRow"/>
            </template>
          </DetailToggler>
        </div>
      </template>
    </vuetable>
    <div class="pt-2" v-if="!infinityScroll">
      <vuetable-pagination
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import {Vuetable, VuetablePagination} from 'vue3-vuetable';
import {throttle} from 'lodash-es';
import Loader from '@/components/ui/Loader';
import NotifyMixin from "@/mixins/NotifyMixin";
import ListActions from "@/components/auth/list/ListActions";
import InlineActions from "@/components/auth/list/InlineActions";
import ListFilter from "@/components/auth/list/ListFilter";
import DetailToggler from "@/components/auth/list/DetailToggler";
import { mapActions } from 'vuex';
const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: 'overflow-x-auto flex-1',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
    detailRowClass: 'bg-blue-100',
  },
  pagination: {
    wrapperClass: 'flex justify-center py-4',
    activeClass: 'active',
    disabledClass: '',
    pageClass: 'btn-paging',
    linkClass: 'btn-paging',
    paginationClass: '',
    paginationInfoClass: 'pagination-info',
    dropdownClass: '',
    icons: {
      first: '',
      prev: '',
      next: '',
      last: '',
    }
  },
};
export default {
  name: 'List',
  data: function () {
    return {
      query: '',
      filter: this.filterMapper(this.initialFilter),
      sort: undefined,
      showFilters: false,
      loading: false,
      scrollContainer: null,
      innerSortOrder: this.sortOrder || [],
      isReloaded: false
    };
  },
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    fields: {
      type: Array,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    infinityScroll: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    onRowClick: {
      type: String,
      default: 'none',
      validator: function (value) {
        return ['edit', 'none', 'details'].indexOf(value) !== -1
      }
    },
    requestParams: {
      type: Object,
      default() {
        return {};
      }
    },
    routeIdParam: {
      type: String,
      default: 'id'
    },
    title: {
      type: String
    },
    sortOrder: {
      type: Array,
      default() {
        return [];
      },
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Start typing to search'
    },
    initialFilter: {
      type: Object,
      default() {
        return {}
      }
    },
    filterClasses: {
      type: Object,
      default: () => ({}),
    },
    filterMapper: {
      type: Function,
      default: function (values) {
        return values;
      }
    },
    responseMapper: {
      type: Function,
      default: function (response) {
        return response;
      }
    },
    dataProvider: {
      type: String,
      default: '$authDataProvider'
    },
    data: {
      type: Array,
      default: null,
    },
    detailRow: {
      type: [String, Object],
      default: ""
    },
    detailRowOptions: {
      type: Object,
      default() {
        return {}
      }
    },
    noDataTemplate: {
      type: String,
      default: 'no data available'
    },
  },
  emits: ['cell-clicked', 'row-clicked'],
  watch: {
    '$route': function () {
      if (this.$route.path === `/${this.basePath}`) {
        this.$refs.vuetable.reload();
      }
    },
    query() {
      this.doSearch();
    },
    filter() {
      this.doSearch();
    },
  },
  components: {
    ListFilter,
    InlineActions,
    Loader,
    Vuetable,
    VuetablePagination,
    ListActions,
    DetailToggler,
  },
  mixins: [NotifyMixin],
  computed: {
    hasFilters() {
      return !!this.$slots.filters;
    },
    hasQuickFilters() {
      return !!this.$slots['quickfilter'];
    },
    hasActions() {
      return !!this.$slots.actions;
    },
    hasInlineActions() {
      return !!this.$slots['inline-actions'];
    },
    hasDetailRow() {
      return this.detailRow !== "";
    },
    hasClickRowListener() {
      return null;
    },
    nonClickableRow() {
      return this.onRowClick === 'none' && !this.hasClickRowListener && !(this.$route.params?.passFlowTo && this.$route.params?.passFlowTo !== this.$route.name);
    },
    detailOptions() {
      return {
        ...this.detailRowOptions,
        vuetable: this.$refs.vuetable,
      }
    },
  },
  methods: {
    ...mapActions({
      setApplication: 'application_service/setApplication',
    }),
    rowClass(dataItem) {
      const classes = ['table-row'];
      if (this.nonClickableRow) {
        classes.push('table-row-nonclickable');
      }
      if (this.hasDetailRow && this.$refs.vuetable?.isVisibleDetailRow(dataItem[this.trackBy])) {
        classes.push('table-row-detail-open');
      }
      return classes.join(' ');
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    applySearch({target: {value}}) {
      this.query = value;
    },
    applyFilter(values) {
      this.filter = values;
      this.$refs.vuetable.changePage(1);
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
      this.isReloaded = false;
    },
    doSearch: throttle(function () {
      if (this.query.length > 2 || this.query.length === 0) {
        this.reload();
      }
    }, 500, {trailing: true}),
    onPaginationData(paginationData) {
      if (!this.infinityScroll) {
        this.$refs.pagination.setPaginationData(paginationData);
      }
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    reload() {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    getSort({ sortField, direction }) {
      const fields = sortField.split('&');
      if (fields.length > 1) {
        return fields.map((item) => {
          const [fieldName, dir = direction] = item.split('|');
          return `${fieldName},${dir}`;
        });
      }
      return `${sortField},${direction}`;
    },
    filterData(data, filterProps) {
      let dataFiltered = data;
      
      if (filterProps.status) {
        dataFiltered = dataFiltered.filter(app => app.application_status.toLowerCase() === filterProps.status.toLowerCase());
      }
      if (filterProps.application_submitedFrom && filterProps.application_submitedTo) {
        dataFiltered = dataFiltered.filter(app => (app.application_submited >= filterProps.application_submitedFrom) &&
        (app.application_submited <= filterProps.application_submitedTo));
      }
      if (filterProps.record_createdFrom && filterProps.record_createdTo) {
        dataFiltered = dataFiltered.filter(app => (app.record_created >= filterProps.record_createdFrom) &&
        (app.record_created <= filterProps.record_createdTo));
      }
      if (this.query.length > 2) {
        dataFiltered = dataFiltered.filter(app => {
          return app.applicants.find(applicant => (applicant.firstName.toLowerCase()).match(this.query.toLowerCase()) || (applicant.lastName.toLowerCase()).match(this.query.toLowerCase()));
        })
      }
      return dataFiltered;
    },
    dataManager(sortOrder = [], pagination = {current_page: 1}) {
      let filteredData = this.filterData(this.data, this.filter);
      const prevData = this.$refs.vuetable?.tableData ?? [];
      const { sort: oldSort } = this;
      const sort = sortOrder[0] ? this.getSort(sortOrder[0]) : undefined;
      const newPagination = this.$refs.vuetable?.makePagination(2, this.pageSize, pagination.current_page);
      this.sort = sort;
      this.innerSortOrder = sortOrder;
      if (filteredData !== this.data && !this.isReloaded) {
        this.isReloaded = true;
        setTimeout(() => {
          this.reload();
        }, 1000);
      }
      return {
        pagination: newPagination,
        data: this.infinityScroll && oldSort === sort
          ? [...prevData, ...filteredData]
          : filteredData,
      };
      /* I will Uncomment these lines once we got real data */
      // this.loading = true;
      // return this[this.dataProvider].getList(
      //   'quextUsers',
      //   {
      //     page: pagination.current_page - 1,
      //     size,
      //     sort,
      //     query,
      //     ...this.requestParams,
      //     ...filter,
      //   })
      //   .then(this.responseMapper)
      //   .then(({content: nextData, totalElements}) => {
      //     const newPagination = this.$refs.vuetable?.makePagination(totalElements, this.pageSize, pagination.current_page);
      //     this.sort = sort;
      //     this.innerSortOrder = sortOrder;
      //     return {
      //       pagination: newPagination,
      //       data: this.infinityScroll && oldSort === sort
      //         ? [...prevData, ...nextData]
      //         : nextData,
      //     };
      //   })
      //   .catch(error => {
      //     this.notifyError(error.message);
      //     return {
      //       pagination: this.$refs.vuetable?.tablePagination,
      //       data: this.$refs.vuetable?.tableData
      //     };
      //   })
      //   .finally(() => this.loading = false);
    },
    handleRowClicked({data}) {
      if (data) {
        this.setApplication({...data});
        this.$router.push({name: 'manage_application.details'});
      }
    },
    handleScrollContainer(e) {
      if (this.$refs.vuetable.tablePagination && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 2) {
        this.onChangePage('next');
      }
    },
  },
  mounted() {
    this.$refs.vuetable.changePage(1);
    if (this.infinityScroll) {
      this.scrollContainer = this.$refs.vuetable?.$el;
      this.scrollContainer.addEventListener('scroll', this.handleScrollContainer);
    }
  },
  beforeUnmount() {
    if (this.infinityScroll) {
      this.scrollContainer.removeEventListener('scroll', this.handleScrollContainer);
    }
  }
};
</script>
<style scoped>
  .list-container {
    @apply relative flex flex-col h-full;
  }
 .list-container :deep(.sort-icon) {
    float: none !important;
    display: inline-block;
    margin-left: 0.5rem;
 }
  .list-container :deep(.vuetable) {
    @apply text-black;
  }
  .list-container :deep(.vuetable th) {
    @apply text-sm font-400 border-b;
  }
  .list-container :deep(.vuetable-empty-result) {
    @apply bg-blue-50 text-blue-700 font-500 text-sm text-center p-6;
    box-shadow: inset 0 0 0 1px #C4DEF0;
  }
 /* remove table hover effect */
  .list-container :deep(.table-row-nonclickable:hover) {
    @apply bg-white;
  }
  .list-container :deep(.table-row-nonclickable:nth-child(even):hover) {
    @apply bg-gray-100;
  }
  /* detail row */
  .list-container :deep(.vuetable-detail-row),
  .list-container :deep(.table-row-detail-open),
  .list-container :deep(.table-row.table-row-detail-open:hover) {
    @apply bg-blue-50;
  }
  .list-container :deep(.vuetable-detail-row > td) {
    box-shadow: inset 0 -1px 0 0 #C4DEF0, inset -1px 0 0 0 #C4DEF0, inset 1px 0 0 0 #C4DEF0;
  }
  .list-container :deep(.table-row-detail-open > td) {
    box-shadow: inset 0 1px 0 0 #C4DEF0;
    &:first-child {
       box-shadow: inset 1px 1px 0 0 #C4DEF0;
     }
    &:last-child {
       box-shadow: inset -1px 1px 0 0 #C4DEF0;
     }
  }
</style>