<template>
  <DropMenu direction="right" v-if="hasActions()" :remove-padding="true" :rounded="false">
    <template v-slot:header>
      <Icon name="dotsVertical" class="list-actions" :class="iconClass" />
    </template>
    <slot name="default"/>
  </DropMenu>
</template>

<script>
  import DropMenu from "@/components/ui/DropMenu";
  import Icon from "@/components/ui/Icon";

  export default {
    name: "ListActions",

    components: {
      Icon,
      DropMenu,
    },

    props: {
      iconClass: {
        type: String,
      },
    },

    methods: {
      hasActions() {
        return !!this.$slots.default;
      }
    }
  }
</script>

<style scoped>
  .list-actions {
    @apply w-6 h-6 cursor-pointer text-graphite-800;
  }

  .list-actions:hover {
    @apply bg-active-25;
  }
</style>
