<template>
  <div class="detail-toggler">
    <slot name="default" :isOpen="isOpen" :toggleRow="toggleRow">
    </slot>
  </div>
</template>

<script>
  export default {
    name: "DetailToggler",
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number,
      },
    },
    computed: {
      vuetable() {
        return this.$parent;
      },
      rowId() {
        return this.rowData[this.vuetable.trackBy];
      },
      isOpen() {
        return this.vuetable.isVisibleDetailRow(this.rowId);
      },
    },
    methods: {
      toggleRow() {
        this.vuetable.toggleDetailRow(this.rowId);
      },
    },
  }
</script>
